import React, { ReactElement } from "react"
import Header from "../components/header"
import Footer from "../components/footer"

const Txt: React.FunctionComponent = props => {
  return <p className="py-2">{props.children}</p>
}

function Privacy(): ReactElement {
  return (
    <>
      <Header page={"Terms of Service"} />
      <div className="w-full flex flex-col items-center justify-center">
        <div className="py-2 px-2 md:py-10 md:px-32 text-md md:text-xl text-gray-800 font-bold">
          <p className="pb-4 text-3xl text-blue-500">TERMS OF SERVICE</p>
          <Txt>
            THIS TERMS OF SERVICE ("AGREEMENT") DESCRIBES THE TERMS AND
            CONDITIONS ON WHICH WE GRANT AN END USER ACCESS TO MIDNIGHT
            ADVENTURES LLC GAMES AND PRODUCTS PROVIDED ON IOS, GOOGLE PLAY AND
            AMAZON. BY DOWNLOADING, INSTALLING, ENTERING, USING, PLAYING OR
            OTHERWISE ACCESSING OUR PRODUCTS, YOU AGREE TO THIS AGREEMENT (THIS
            AGREEMENT MAY BE AMENDED FROM TIME TO TIME). PLEASE READ THE
            FOLLOWING INFORMATION CAREFULLY. IF YOU DO NOT AGREE TO THE TERMS
            AND CONDITIONS OF THIS AGREEMENT, EXIT THIS PAGE AND STOP ACCESSING
            THE DIGITAL CONTENT AND PRODUCTS. THE PRODUCTS ARE PROTECTED UNDER
            INTERNATIONAL INTELLECTUAL RIGHTS. THE PRODUCTS ARE ONLY ALLOWED FOR
            PERSONAL USE; THEY MAY NOT, AT ANY TIME, BE USED OR DISTRIBUTED FOR
            COMMERCIAL USE OR ACTIVITIES.
          </Txt>
          <Txt>
            Please read this terms of service agreement. If you do not agree to
            the terms and conditions stated within this agreement, you will not
            be provided the rights to use Midnight Adventures Games & Apps. Your
            use of Midnight Adventures apps and services constitute your
            agreement to all such terms and conditions. Midnight Adventures LLC
            grants you the end user rights to this application under the
            guidance of this agreement. You must agree to the following
            conditions stated put forth here:
          </Txt>
          <Txt>1. Software Protection Provisions</Txt>
          <Txt>
            a) Licensing and Restrictions: Authorization: Midnight Adventures
            LLC authorizes users the rights to use Midnight Adventures LLC
            products: You may install this product unlimited times for
            non-commercial use. Restrictions: Please note that other than
            explicitly stated under this agreement, Midnight Adventures LLC does
            not grant you any other rights. You may not, at any time distribute,
            copy, reverse engineer, decompile, or reassemble products from
            Midnight Adventures LLC. Any unauthorized usage of Midnight
            Adventures games & apps in public activities for personal gains,
            such as distributing, reproducing, publishing, re-skinning or
            disseminating, will be viewed as an infringement, and Midnight
            Adventures LLC may take legal means to pursue legal action against
            such party who is involved in any unauthorized use as outlined
            above.
          </Txt>
          <Txt>
            b) Termination: If you failed to comply with any of the terms and
            conditions stated in this agreement, the agreement will be terminate
            immediately. Individual mandate: Any organization or person must
            receive a written consent by Midnight Adventures LLC before
            distributing, reproducing, re-skinning, publishing or disseminating
            this product, otherwise such actions will be viewed as infringement
            & legal action will follow.
          </Txt>
          <Txt>
            c) Intellectual Property and Business Trademark: This agreement does
            not grant you the rights to use any trademark, logo or any
            commercial representation that reflects our commercial identity
            (“Logo”) owned by Midnight Adventures. Midnight Adventures products
            (including, but not limited to, images, sound, animations, written
            characters and engineering, programming, voice over FX, sound FX,
            code, API libraries or any contents within the products themselves
            as they are proprietary property of Midnight Adventures LLC) and any
            derivative products are properties belonging to Midnight Adventures
            LLC, who possesses full ownership, intellectual property rights and
            copyright of these products. This agreement does not grant you the
            rights to these contents.
          </Txt>
          <Txt>
            d) Limited Warranty, No Warranty: The use of this product requires
            internet access, and may lead to many conditions of instability,
            where the services may lead to vulnerability, computer viruses,
            hacks, operating system crashes, user location inaccuracy, device
            shut down or malfunctions, or any other technical issues related to
            software, internet connection, or telecommunication that may result
            in service interruption or any risks may decrease user satisfaction.
            User will assume all the above mentioned risks, and Midnight
            Adventures LLC hereby declares zero-fault for any of the risks users
            may take while accessing Midnight Adventures products such as
            search, download, or message related issues; the user party will
            taken full risk and responsibility, and Midnight Adventures LLC will
            not compensate users for any losses, under any circumstances, as
            allowed by law, and herein notifying all parties of the risks
            involved. No Liability for Losses: When using our products, you may
            be exposed to violation of privacy, copyright, or illegal contents
            or conducts, or violation of the rights of others (including
            intellectual property) caused by an anonymous or known third party.
            User will assume all risks, and Midnight Adventures does not make
            any types of guarantees or commitments, whether expressed or
            implied, to the authenticity, applicability and suitability of
            information or content, or any warranties pertaining to usage or
            infringement activities. Therefore, we will not take any
            responsibility for any direct, indirect, incidental, special or
            consequential liabilities due to the improper or illegal use of our
            products.
          </Txt>
          <Txt>
            2. User Notice Users are reminded under this agreement that they
            must comply with relevant national policies and laws guiding the use
            of internet, such as criminal law, national security law, privacy
            law, computer information system security regulations, protection of
            national interests and the protection of national security. Users
            will take full responsibility for the improper or illegal usage of
            the internet.
          </Txt>
          <Txt>
            a) Users must use Midnight Adventures Apps & Games and series in
            compliance with any and all relevant laws and regulations, and at no
            time should our products be used for or used in association with the
            sending or promoting sensitive information pertaining to illegal
            activities or activities that threatens national security; users may
            not use our product in association with sending or promoting false,
            harassing, abusive, threatening, provocative, vulgar, obscene or
            pornographic information or materials; users may not, at any time,
            use Midnight Adventures LLC products and contents for commercial
            purposes without a written consent by Midnight Adventures LLC.
          </Txt>
          <Txt>
            b) We comply with COPPA (the Children’s Online Privacy Protection
            Act). When using our Products, we may read non- personal information
            (such as internet, Wi-Fi availability, network connection, wake
            lock, device status, internal and external storage availability and
            usability) for the use of Product development and Service
            improvement. The collection of information will be used for the
            development and functional improvement of our Products to ensure a
            quality user experience. We only collect such information to the
            extent that allow us to conduct our normal business operation and
            Products’ research and development. We hereby declare that any
            information we received will be used solely for the purpose of
            product functionality and development, and no information will be
            disclosed to any third person party unless we have received your
            consent or under government order.
          </Txt>
          <Txt>
            c) Like other common internet software, Midnight Adventures products
            are susceptible to various security issues, such as viruses from the
            infected software or files downloaded onto a device that threaten
            the security of personal information and data, and likewise,
            Midnight Adventures information and data; therefore, we strongly
            advise users to strengthen personal data protection to prevent any
            inconvenience to personal activities and the normal use of our
            products without unnecessary disruption.
          </Txt>
          <Txt>
            d) Children and adolescents, namely children under the age of 13,
            must use our products and internet service under the supervision of
            an adult; we strongly advise parents and guardians to monitor their
            children’s activities in our products, email exchanges, and other
            online events and activities.
          </Txt>
          <Txt>
            e) Users must not spread unfavorable remarks or actions that reflect
            negatively on Midnight Adventures’s image; ranging in severity,
            Midnight Adventures LLC may take legal actions against the person or
            organization responsible.
          </Txt>
          <Txt>
            f) Users must not, at any time, use our products in association with
            activities that violate national laws. If discovered, Midnight
            Adventures LLC will assist the public authority in the investigation
            work.
          </Txt>
          <Txt>
            3. Third Party Liabilities Please note that Midnight Adventures LLC
            will take no responsibility for any violations of rights by other
            parties (including, but not limited to, intellectual property), as
            such, we will not take responsibility for the liabilities by any
            party using our products or services to deliver messages or
            contents.
          </Txt>
          <Txt>
            4. Law and Guidance This agreement complies with current guidelines
            of both national international law and the current guidelines from
            the Appstore set forth as of April 2021, of which this agreement is
            guided by. From time to time, this agreement will be amended in
            accordance with updated guidelines from iOS, Google, and the Amazon
            Appstore, otherwise, all conditions stated in this agreement will
            stand and hold.
          </Txt>
          <Txt>
            5. Amendments We may amend (such as update, modify, correct, or
            terminate) this agreement (including any additional terms and
            conditions applicable to any specific products and services) at any
            time in our sole discretion by posting the amended agreement on our
            website or on or within the services. The amendments must comply
            with the national laws and appstore guidelines, and must not produce
            any harm to users’ basic rights to services. If you do not agree to
            the amended agreement, immediately stop accessing the products,
            otherwise, Midnight Adventures LLC has the rights to assume your
            acceptance to the amended agreement.
          </Txt>
          <Txt>
            6. Effective Date This agreement is posted on the Midnight
            Adventures Website. This agreement will take effect upon your
            initial use of our products or services.
          </Txt>
          <Txt>
            7. Disclaimer Midnight Adventures LLC reserves the rights to the
            final interpretation to the terms and conditions of this agreement
            and its contents.
          </Txt>
          <Txt>Midnight Adventures LLC.</Txt>
          <Txt>04/2021</Txt>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Privacy
